import React from "react";
import { Link } from "react-router-dom";

const Full = () => {
  return (
    <div className="logo">
      <Link className="logo-link" to="/" title="Energizer">
        <div className="energizer"/>
        <div className="homepower"/>
      </Link>
    </div>
  );
}

export default Full;
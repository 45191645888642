import React from "react";
import { withRouter } from 'react-router'
import { NavbarBrand, NavbarToggler, Collapse, Navbar } from "reactstrap";
import { observer } from 'mobx-react';
import Logo from "../Logo";
import Menu from "./Menu";

@observer
class Header extends React.Component {
  state = {
    navbarOpen: false
  }

  constructor(props) {
    super(props);

    this.props.history.listen(() => this.setState({navbarOpen: false}));
  }

  toggle = () => this.setState({navbarOpen: !this.state.navbarOpen});

  render() {
    const { navbarOpen } = this.state;
    return (
      <div className="container">
        <Navbar dark expand="md">
          <NavbarBrand href="/">
            <Logo.Short />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={navbarOpen} navbar>
            <Menu location={this.props.location} />
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

export default withRouter(Header);

import React from 'react';

const Footer = () => {
  const year = (new Date()).getFullYear();
  return (
    <footer className="app-footer">
      <div className="container">

        <div className="disclaimer">
          &copy; { year } Energizer.
          Energizer and certain graphic designs are trademarks of Energizer Brands, LLC and related subsidiaries and are used under license by 8 Star Energy Pty Ltd. All other brand names are trademarks of their respective owners. Neither 8 Star Energy Pty Ltd nor Energizer Brands, LLC is affiliated with the respective owners of their trademarks.
        </div>
      </div>
    </footer>
  );
}

export default Footer;

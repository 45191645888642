(function () {
  'use strict';

  function sleep (ms) {
   return new Promise(resolve => setTimeout(resolve, ms));
  }

  if (typeof module !== 'undefined' && module.exports) {
    sleep.default = sleep;
    module.exports = sleep;
  } else if (typeof define === 'function' && typeof define.amd === 'object' && define.amd) {
    // register as 'classnames', consistent with npm package name
    define('sleep', [], function () {
      return sleep;
    });
  } else {
    window.sleep = sleep;
  }
}());

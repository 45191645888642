import React from "react";
import Logo from 'components/App/Logo';
import Footer from 'components/App/Footer';
import Header from "../Header";

const Start = props => {
  return (
    <div className="app-start-page container d-flex flex-column">
      <div className="app-start-container">
        <header className="app-header">
          <Header />
        </header>
        <div className="app-start-header">
          <Logo.Full />
        </div>
        <div className="app-start-content">
          { props.children }
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Start;
import React from 'react';
import activeDeviceStore from "./stores/ActiveDeviceStore";
import { observer } from "mobx-react";

const withPageTitle = (WrappedComponent, title) => {
  let component = class extends React.Component {

    componentDidMount() {
      if (title !== '') {
        const device = activeDeviceStore.device;
        if (null !== device) {
          title = title.replace(/\:id/, device.serialNumber)
        }
        document.title = title + '. Homepower - Control Center';
      }
    }

    componentWillUnmount() {
      document.title = 'Homepower - Control Center';
    }


    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return component;
};

export default withPageTitle;
import React from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import Header from '../Header';
import Footer from '../Footer';

const Main = props => {
  //set default height and overflow for iOS mobile Safari 10+ support.
  if (isIOS && isMobile) {
    document.body.classList.add('ios-mobile-view-height')
  } else if (document.body.classList.contains('ios-mobile-view-height')) {
    document.body.classList.remove('ios-mobile-view-height')
  }

  return (
    <div className={`app-container fixed-drawer`}>
      <header className="app-header">
        <Header location={props.location}/>
      </header>

      <main className="app-content container">
        { props.children }
      </main>
      <Footer />
    </div>
  );
}

export default Main;

import http from './http';
import moment from "moment";

const PlantSettings = {
  getNotifications: (deviceId) =>
    http.get('/v1/plant/settings/notifications', {params: {plant_id: deviceId}}),
  setNotifications: (deviceId, sendDailySummaryReport, sendWeeklySummaryReport, notifyAboutPowerOutage) =>
    http.post('/v1/plant/settings/notifications', {
      plant_id: deviceId,
      sendDailySummaryReport,
      sendWeeklySummaryReport,
      notifyAboutPowerOutage
    }),
  getEnergyRate: (deviceId) =>
    http.get('/v1/plant/get-energy-rate-information', {params: {plant_id: deviceId}}),
  setEnergyRate: (deviceId, rate) =>
    http.post('/v1/plant/set-energy-rate-information', {
      plant_id: deviceId,
      buy_tariff_type: rate.buyTariffType,
      buy_tariff_value_1: rate.buyTariffValue1,
      buy_tariff_value_2: rate.buyTariffValue2,
      buy_tariff_value_3: rate.buyTariffValue3,
      weekdays_peak_from: rate.weekdaysPeakFrom,
      weekdays_peak_to: rate.weekdaysPeakTo,
      weekends_peak_from: rate.weekendsPeakFrom,
      weekends_peak_to: rate.weekendsPeakTo,
      weekdays_off_peak_from: rate.weekdaysOffPeakFrom,
      weekdays_off_peak_to: rate.weekdaysOffPeakTo,
      weekends_off_peak_from: rate.weekendsOffPeakFrom,
      weekends_off_peak_to: rate.weekendsOffPeakTo,
      weekdays_shoulder_from: rate.weekdaysShoulderFrom,
      weekdays_shoulder_to: rate.weekdaysShoulderTo,
      weekends_shoulder_from: rate.weekendsShoulderFrom,
      weekends_shoulder_to: rate.weekendsShoulderTo,
      feed_in_tariff: rate.feedInTariff,
      daily_supply_charge: rate.dailySupplyCharge,
      energy_rate_retailer: rate.retailer
    }),
  searchEnergyRateRetailers: (term) =>
    http.get('/v1/plant/energy-rate-retailer-autocomplete', {params: {term}}),
  getApplicationMode: (deviceId, force) =>
    http.get('/v1/plant/application-method', {params: {plant_id: deviceId, force: force === true ? 1 : 0}}),
  setApplicationMode: (deviceId, applicationMode, pvChargePercent, acChgSocLimit, schedule) =>
    http.post('/v1/plant/application-method', {
      plant_id: deviceId,
      applicationMode: applicationMode,
      pvChargePercent: pvChargePercent,
      acChgSocLimit: acChgSocLimit,
      scheduleIntervals: schedule
    }),
  getReserveLimit: (deviceId) =>
    http.get('/v1/plant/settings', {params: {plant_id: deviceId}}),
  setReserveLimit: (deviceId, socLowOnGrid, socLowOffGrid) =>
    http.post('/v1/plant/settings', {
      plant_id: deviceId,
      socLowOnGrid,
      socLowOffGrid
    }),
};

export default PlantSettings;

import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { DropdownMenu, DropdownToggle, Dropdown } from "reactstrap";
import { Parser } from "html-to-react";
import Icon from "../../Icons";
import userNotificationsStore from "../../../stores/UserNotificationsStore";

@observer
class UserNotifications extends React.Component {
  state = {
    dropdownOpen: false,
    unreadCount: 0,
  }

  componentDidMount = async() => {
    await userNotificationsStore.getAll();
  }

  toggle = () => {
    const { dropdownOpen } = this.state;

    if (dropdownOpen) {
      userNotificationsStore.clicked();
    } else {
      if (userNotificationsStore.unreadCount > 0) {
        this.setState({
          unreadCount: userNotificationsStore.unreadCount
        });
        userNotificationsStore.getUnread();
      }
    }

    this.setState({
      dropdownOpen: !dropdownOpen
    });
  }

  refresh = async() => {
    this.setState({
      unreadCount: userNotificationsStore.unreadCount
    });
    await userNotificationsStore.getUnread();
  }

  clear = async() => {
    this.setState({
      unreadCount: userNotificationsStore.unreadCount
    });
    await userNotificationsStore.clear();
  }

  render() {
    const { dropdownOpen, unreadCount: unreadCountState } = this.state;
    const { unreadCount, list } = userNotificationsStore;

    if (dropdownOpen && unreadCountState !== unreadCount) {
      this.refresh();
    }

    return (
      <Dropdown nav inNavbar className={classNames("notifications", { unread: unreadCount > 0 })} isOpen={dropdownOpen} toggle={this.toggle}>
        <DropdownToggle nav className="">
          <Icon name="bell" fw />&nbsp;<span className="nav-text">Notifications</span>
          {
            unreadCount > 0 &&
            <div className="unread-count">{unreadCount}</div>
          }
        </DropdownToggle>
        <DropdownMenu right className="user-notification">
          {
            list.length > 0 &&
            <div
              className={classNames("user-notification__unread-total", {"user-notification__unread-total_full": unreadCount > 0})}>
              {
                unreadCount > 0 &&
                <>
                  You have {unreadCountState} new message{unreadCountState > 1 && 's'}
                </>
              }
              {
                unreadCount === 0 &&
                'No new messages'
              }
              <div className="user-notification__clear-btn" onClick={this.clear}>
                <Icon name="trash"/>
              </div>
            </div>
          }
          <div className={classNames("user-notification__list", {"user-notification__list_empty": list.length === 0})}>
            {
              list.length === 0 &&
              <div className="user-notification__list-stub">
                No Notifications
              </div>
            }
            {
              list.map((notification, key) => {
                return (
                  <div key={key} className={classNames("user-notification__item", { "user-notification__item_unread": !notification.read })}>
                    {
                      notification.icon !== null &&
                      <div className={classNames("user-notification__icon", "user-notification__icon_" + notification.icon)} />
                    }
                    <div className="user-notification__text">
                      {
                        notification.text &&
                        Parser().parse(notification.text)
                      }
                    </div>
                    <div className="user-notification__created">
                      {notification.createdAt.format('MMM DD h:mm a')}
                    </div>
                  </div>
                );
              })
            }
          </div>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default UserNotifications;
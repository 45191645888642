import Main from './Main';
import Modal from './Modal';
import Start from './Start';
import User from './User';

export default {
  Main,
  Modal,
  Start,
  User,
}

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'Routes';
import 'react-big-calendar/lib/sass/styles.scss';
import "react-toggle-switch/dist/css/switch.min.css";
import 'rc-drawer/assets/index.css';
import 'styles/app.scss';


const App = () => {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
};

export default App;

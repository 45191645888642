import http from './http';

const UserNotifications = {
  unreadCount: () =>
    http.get('/v1/notification/get-count'),
  unread: (limit) =>
    http.get('/v1/notification/get-unread', {params: {page_size: limit || 10}}),
  all: () =>
    http.get('/v1/notification/get-all'),
  setClicked: ids =>
    http.post('/v1/notification/set-clicked', ids),
  clear: () =>
    http.post('/v1/notification/clear'),
};

export default UserNotifications;

import AuthStore from './AuthStore';

class RootStore {
  constructor() {
    this.authStore = new AuthStore(this);
  }

  stores() {
    return {
      rootStore: this,
      authStore: this.authStore,
    }
  }
}

export default RootStore;

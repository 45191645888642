import http from './http';
import Auth from './Auth';
import Faq from './Faq';
import Plant from './Plant';
import PlantSettings from './PlantSettings';
import Profile from './Profile';
import Report from './Report';
import UserNotifications from './UserNotifications';
import Weather from './Weather';

export default {
  http,
  Auth,
  Faq,
  Plant,
  PlantSettings,
  Profile,
  Report,
  UserNotifications,
  Weather
}

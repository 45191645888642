import http from './http';
import moment from 'moment';

const Plant = {
  current: () => 
    http.get('/v1/user/get-my-profile'),
  info: deviceId =>
    http.get('/v1/plant/get-by-id', {params: {plant_id: deviceId}}),
  getEnergyFlow: (deviceId, rtd) =>
    http.get('/v1/report/get-energy-flow', {params: {id: deviceId, realtime: rtd ? 1 : 0}}),
  remindControlCode: (postcode, lastname) =>
    http.post('/v1/plant/remind-control-code', {postcode, lastname}),
  link: (serialNumber, verificationCode) =>
    http.post('/v1/plant/add-plant', {serial_number: serialNumber, verification_code: verificationCode}),
  isOnline: (deviceId) =>
    http.get('/v1/plant/is-online', {params: {plant_id: deviceId}}),
  reboot: (deviceId) =>
    http.get('/v1/plant/reboot', {params: {plant_id: deviceId}}),
};

export default Plant;

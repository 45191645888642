import React from "react";
import { inject, observer } from 'mobx-react';
import api from 'api';

@inject("authStore")
@observer
class SigninWithToken extends React.Component {
  state = {
    token: null
  }

  constructor(props) {
    super(props);

    let { token } = props.match.params;

    this.state = {
      token
    }
  };

  componentWillMount() {
    this.props.authStore.logout()
      .then(() => {
        api.Auth.loginWithToken(this.state.token)
          .then(({ data }) => this.props.authStore.setCurrentUser(data))
          .finally(() => {
            window.location.href = '/';
          })
      });
  }

  render() {
    return (
      <div className="app-login-page__wrapper">
        <div className="app-login-page__loader">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    );
  }
}

export default SigninWithToken;

import Recovery from './Recovery';
import Signin from './Signin';
import SigninWithToken from './SigninWithToken';
import Signup from './Signup';

export default {
  Recovery,
  Signin,
  SigninWithToken,
  Signup,
}

import React from 'react';
import CircularProgress from 'components/CircularProgress';

const PageLoader = () => <div className="page-loader">
  <div className="loader-view">
    <CircularProgress />
  </div>
</div>

export default PageLoader;

import React, { Component } from 'react'
import classnames from 'classnames';

export default class Icon extends Component {
  render () {
    const { name, fw, className } = this.props;

    return (
      <span className={classnames("icon icon-" + name, className, {'icon-fw': fw})} />
    )
  }
}
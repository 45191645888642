import React from 'react';

export default class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    loadingChunkError: false
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      loadingChunkError: /loading chunk \d* failed./i.test(error.message)
    };
  }

  render() {
    if (this.state.hasError) {
      if (this.state.loadingChunkError) {
        window.location.reload();
        return;
      }

      return <h1>Error</h1>;
    }

    return this.props.children;
  }
}
import { observable, action, reaction } from 'mobx';
import api from 'api';
import {
  auth as socialAuth,
  appleAuthProvider,
  facebookAuthProvider,
  googleAuthProvider
} from 'firebase/firebase';
import profileStore from "./ProfileStore";

class AuthStore {
  @observable currentUser = window.localStorage.getItem('currentUser');
  @observable accessToken = window.localStorage.getItem('access-token');
  @observable refreshToken = window.localStorage.getItem('refresh-token');
  @observable socialUser = null;
  @observable inProgress = false;
  @observable errors = undefined;
  @observable formErrors = {};

  @observable values = {
    username: '',
    password: '',
    email: '',
    firstname: '',
    lastname: '',
    phone: '',
    recoveryCode: ''
  };

  constructor(rootStore) {
    this.rootStore = rootStore;

    reaction(
      () => this.accessToken,
      token => {
        if (token) {
          window.localStorage.setItem('access-token', token);
        } else {
          window.localStorage.removeItem('access-token');
        }
      }
    );
    reaction(
      () => this.refreshToken,
      token => {
        if (token) {
          window.localStorage.setItem('refresh-token', token);
        } else {
          window.localStorage.removeItem('refresh-token');
        }
      }
    );
    reaction(
      () => this.currentUser,
      currentUser => {
        if (currentUser) {
          window.localStorage.setItem('currentUser', currentUser.username);
        } else {
          window.localStorage.removeItem('currentUser');
        }
      }
    );
  }

  @action setTokens(accessToken, refreshToken) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
  }

  @action setCurrentUser(currentUser) {
    this.currentUser = currentUser;
    if (currentUser) {
      this.setTokens(currentUser.tokens.access_token, currentUser.tokens.refresh_token);
    }
  }

  @action setSocialUser(socialUser) {
    this.socialUser = socialUser;
  }

  @action setUsername(username) {
    this.values.username = username;
  }

  @action setPassword(password) {
    this.values.password = password;
  }

  @action setEmail(email) {
    this.values.email = email;
  }

  @action setFirstname(firstname) {
    this.values.firstname = firstname;
  }

  @action setLastname(lastname) {
    this.values.lastname = lastname;
  }

  @action setPhone(phone) {
    this.values.phone = phone;
  }

  @action setRecoveryCode(code) {
    this.values.recoveryCode = code;
  }

  @action reset() {
    this.inProgress = false;
    this.values.username = '';
    this.values.password = '';
    this.values.email = '';
    this.values.firstname = '';
    this.values.lastname = '';
    this.values.phone = '';
    this.values.recoveryCode = '';
    this.errors = undefined;
    this.formErrors = {};
  }

  @action signIn() {
    this.inProgress = true;
    this.errors = undefined;
    this.formErrors = {};
    return api.Auth.login(this.values.username, this.values.password)
      .then(({ data }) => this.setCurrentUser(data))
      .catch((err) => {
        this.errors = [err.response && err.response.data && err.response.data.message];
        throw err;
      })
      .catch((err) => {
        this.formErrors = err.response && err.response.data && err.response.data.arguments;
        throw err;
      })
      .finally(() => { this.inProgress = false; });
  }

  @action signInBySocialToken(token) {
    this.inProgress = true;
    this.errors = undefined;
    this.formErrors = {};
    return api.Auth.loginSocial(token)
      .then(({ data }) => {
        if (data.user) {
          this.setCurrentUser(data.user);
        } else {
          this.setSocialUser(data.data);
        }
        return data;
      })
      .catch((err) => {
        this.errors = [err.response && err.response.data && err.response.data.message];
        throw err;
      })
      .catch((err) => {
        this.formErrors = err.response && err.response.data && err.response.data.arguments;
        throw err;
      })
      .finally(() => { this.inProgress = false; });
  }

  @action register() {
    this.inProgress = true;
    this.errors = undefined;
    this.formErrors = {};

    const request = this.socialUser !== null ? api.Auth.registerBySocial(this.socialUser.token, this.values.email, this.values.firstname, this.values.lastname) : api.Auth.register(this.values.username, this.values.password, this.values.email, this.values.phone, this.values.lastname, this.values.firstname);

    return request.then(({ data }) => this.setCurrentUser(data))
      .catch((err) => {
        this.errors = [err.response && err.response.data && err.response.data.message];
        throw err;
      })
      .catch((err) => {
        this.formErrors = err.response && err.response.data && err.response.data.arguments;
        throw err;
      })
      .finally(() => { this.inProgress = false; });
  }

  @action recoverySendCode(username) {
    this.inProgress = true;
    this.errors = undefined;
    this.formErrors = {};
    return api.Auth.recoverySendCode(username)
      .catch((err) => {
        this.errors = [err.response && err.response.data && err.response.data.message];
        throw err;
      })
      .catch((err) => {
        this.formErrors = err.response && err.response.data && err.response.data.arguments;
        throw err;
      })
      .finally(() => { this.inProgress = false; });
  }

  @action recoveryValidateCode(username, recoveryCode) {
    this.inProgress = true;
    this.errors = undefined;
    this.formErrors = {};
    return api.Auth.recoveryValidateCode(username, recoveryCode)
      .catch((err) => {
        this.errors = [err.response && err.response.data && err.response.data.message];
        throw err;
      })
      .catch((err) => {
        this.formErrors = err.response && err.response.data && err.response.data.arguments;
        throw err;
      })
      .finally(() => { this.inProgress = false; });
  }

  @action logout() {
    this.setTokens(undefined, undefined);
    this.setCurrentUser(undefined);
    return Promise.resolve();
  }

  @action appleSignIn() {
    this.inProgress = true;
    this.errors = undefined;
    this.formErrors = {};
    return socialAuth.signInWithPopup(appleAuthProvider)
      .then(authUser => this.signInBySocialToken(authUser.user.ma))
      .catch((error) => {
        this.errors = [error.message];
        throw error;
      })
      .finally(() => { this.inProgress = false; });
  }

  @action facebookSignIn() {
    this.inProgress = true;
    this.errors = undefined;
    this.formErrors = {};
    return socialAuth.signInWithPopup(facebookAuthProvider)
      .then(authUser => this.signInBySocialToken(authUser.user.ma))
      .catch((error) => {
        this.errors = [error.message];
        throw error;
      })
      .finally(() => { this.inProgress = false; });
  }

  @action googleSignIn() {
    this.inProgress = true;
    this.errors = undefined;
    this.formErrors = {};
    return socialAuth.signInWithPopup(googleAuthProvider)
      .then(authUser => this.signInBySocialToken(authUser.user.ma))
      .catch((error) => {
        this.errors = [error.message];
        throw error;
      })
      .finally(() => { this.inProgress = false; });
  }
}

export default AuthStore;
import http from './http';

const Faq = {
  list: () => 
    http.get('/v1/faq/get-all-faq').then((response) => {
      return response;
    }),
};

export default Faq;

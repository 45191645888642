import React from "react"
import Cookies from "universal-cookie"

const COOKIE_NAME = "cookies-clause-result"

const CookiesClause = () => {
  const cookies = new Cookies()
  const currentCookie = cookies.get(COOKIE_NAME)

  const [showNeeded, setShowNeeded] = React.useState(typeof currentCookie === "undefined")

  const setCookie = (result) => {
    cookies.set(COOKIE_NAME, result, { path: "/" })
    setShowNeeded(false)
  }
  const allowCookies = (e) => {
    e.preventDefault()
    setCookie(1)
  }
  const declineCookies = (e) => {
    e.preventDefault()
    setCookie(0)
  }

  if (!showNeeded) return null

  return (
    <aside className="cookies-clause">
      <div className="container">
        <div className="cookies-clause__content">
          <div className="cookies-clause__notice">
            <p>
              We use cookies to make the <em className="energizer-brand-name">Energizer</em> Homepower website a better
              place. Cookies help to provide a more personalised experience and relevant advertising for you, and web
              analytics for us. To learn more about the different cookies we're using, check out our{" "}
              <a style={{ color: "#44AECA" }} href="https://energizerhomepower.com/cookie-policy/">
                Cookie Policy
              </a>
              .
            </p>
          </div>

          <div className="cookies-clause__buttons">
            <button
              className="btn btn-primary rounded-pill cookies-clause__button cookies-clause__button_primary"
              aria-label="Allow Cookies"
              onClick={allowCookies}
            >
              Allow Cookies
            </button>
            <button
              className="btn cookies-clause__button cookies-clause__button_reject"
              aria-label="Decline"
              onClick={declineCookies}
            >
              Decline
            </button>
          </div>
        </div>
      </div>
    </aside>
  )
}

export default CookiesClause

import http from "./http"

const Report = {
  getPowerDiagram: (plantId, date) => http.get("/v1/report/get-power-diagram", { params: { plant_id: plantId, date } }),
  getGenerationReport: (plantId, date) => http.get("/v1/report/generation", { params: { plant_id: plantId, date } }),
  getEnergyConsumptionReport: (plantId, date) =>
    http.get("/v1/report/energy-consumption", { params: { plant_id: plantId, date } }),
  getEnergyReport: (plantId, date) => http.get("/v1/report/get-energy-report", { params: { plant_id: plantId, date } }),
  dailyReport: (plantId, date) => http.get("/v1/report/daily-report", { params: { plant_id: plantId, date } }),
  monthlyReport: (plantId, date) => http.get("/v1/report/monthly-report", { params: { plant_id: plantId, date } }),
  availableDates: (plantId, date, period) =>
    http.get("/v1/report/available-dates", { params: { plant_id: plantId, date, period } }),
  lifetimeReport: (plantId) => http.get("/v1/report/lifetime-report", { params: { plant_id: plantId } }),
  requestIntervalData: (plant_id, from, to) => http.post("/v1/report/request-interval-data", { plant_id, from, to }),
}

export default Report

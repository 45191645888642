import { observable, action } from 'mobx';
import api from 'api';

class UserNotificationsStore {
  @observable unreadCount = 0;
  @observable list = [];
  ids = [];
  unclickedIds = [];
  timerId = setInterval(async() => {
    const currentUser = window.localStorage.getItem('currentUser');
    if (currentUser) {
      if (this.isVisible()) {
        await this.getUnreadCount();
      }
    }
  }, 60000);

  @action getUnreadCount = async() => {
    const result = await api.UserNotifications.unreadCount().then(({ data }) => data);
    this.unreadCount = result.count;
  }

  @action getUnread = async() => {
    const result = await api.UserNotifications.unread(this.unreadCount).then(({ data }) => data);
    this.merge(result.notifications);
  }

  @action getAll = async() => {
    const result = await api.UserNotifications.all().then(({ data }) => data);
    this.merge(result.notifications);
  }

  @action clear = async() => {
    const result = await api.UserNotifications.clear().then(({ data }) => data);
    this.unreadCount = 0;
    this.list = [];
    this.unclickedIds = [];
    this.ids = [];
    console.log(result);
  }

  @action clicked = async() => {
    if (this.unclickedIds.length === 0) {
      return;
    }
    await api.UserNotifications.setClicked(this.unclickedIds);
    this.list.forEach(data => {
      if (-1 !== this.unclickedIds.indexOf(data.id)) {
        data.read = true;
        data.clicked = true;
      }
    });
    this.unclickedIds = [];
  }

  merge = list => {
    list.forEach(data => {
      const { notification_id, notification_type, created_at, read, clicked, icon, text } = data;
      if (-1 === this.ids.indexOf(notification_id)) {
        this.ids.push(notification_id);

        if (!data.clicked) {
          this.unclickedIds.push(notification_id);
        }

        this.list.push({
          id: notification_id,
          type: notification_type,
          createdAt: moment(created_at),
          read: read,
          clicked: clicked,
          icon: icon,
          text: text,
        });
      }
    });
  }

  isVisible = (function() {
    var stateKey, eventKey, keys = {
      hidden: "visibilitychange",
      webkitHidden: "webkitvisibilitychange",
      mozHidden: "mozvisibilitychange",
      msHidden: "msvisibilitychange"
    };
    for (stateKey in keys) {
      if (stateKey in document) {
        eventKey = keys[stateKey];
        break;
      }
    }
    return function(c) {
      if (c) {
        document.addEventListener(eventKey, c);
      }
      return !document[stateKey];
    };
  })();
}

const userNotificationsStore = new UserNotificationsStore();

export default userNotificationsStore;
import { action, computed, observable, reaction, toJS } from 'mobx';
import deviceListStore from "./DeviceListStore";

class ActiveDeviceStore {
  @observable deviceId = null;
  @observable info = null;
  @observable loaded = false;

  constructor() {
    reaction(
      () => this.info,
      (data) => {
        if (data && data.id) {
          const { id } = data;
          window.localStorage.setItem('device-id', id);
        } else {
          window.localStorage.removeItem('device-id');
        }
      }
    );
  }

  @computed get id() {
    if (null !== this.deviceId) {
      return this.deviceId;
    }

    let id = window.localStorage.getItem('device-id');
    if (id) {
      return (async () => {
        await this.select(id);
        return this.deviceId;
      })();
    }

    return null;
  }

  set device(data) {
    this.info = toJS(data);
    this.deviceId = data.id;
    this.loaded = true;
  }

  @computed get device() {
    if (!this.loaded) {
      return (async () => {
        await this.id;
        return this.info;
      })();
    }
    return this.info;
  }

  @action select = async id => {
    const device = await deviceListStore.device(parseInt(id));
    if (null !== device) {
      this.device = device;
    }
  }
}

const activeDeviceStore = new ActiveDeviceStore();

export default activeDeviceStore;
import React from 'react';
import { observer } from 'mobx-react';
import PageLoader from 'components/PageLoader';
import activeDeviceStore from "./stores/ActiveDeviceStore";

const withActivePlant = (WrappedComponent) => {
  let SearchActivePlant = class extends React.Component {
    state = {
      device: null
    }

    componentDidMount = async () => {
      const id = await activeDeviceStore.id;

      if (null === id) {
        this.props.history.push('/');
      }

      this.setState({
        device: activeDeviceStore.info
      });
    }

    render() {
      const { device } = this.state;

      if (!device) {
        return (
          <PageLoader />
        );
      }

      return <WrappedComponent device={ device } {...this.props} />;
    }
  };

  return observer(SearchActivePlant);
};

export default withActivePlant;

import React from "react"
import { withRouter } from "react-router"
import { Link, NavLink } from "react-router-dom"
import { LinkContainer } from "react-router-bootstrap"
import { observer } from "mobx-react"
import { DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, Nav, NavItem } from "reactstrap"
import Icon from "../../Icons"
import activeDeviceStore from "../../../stores/ActiveDeviceStore"
import deviceListStore from "../../../stores/DeviceListStore"
import profileStore from "../../../stores/ProfileStore"
import UserNotifications from "./UserNotifications"
import DownloadIntervalData from "../../DownloadIntervalData"

@observer
class Menu extends React.Component {
  changeDeviceHandler = async (id) => {
    if (activeDeviceStore.id === id) {
      return
    }

    await activeDeviceStore.select(id)
    this.props.history.push("/device/dashboard")
  }

  render() {
    const { username } = profileStore.user
    const { info: activeDevice } = activeDeviceStore
    const deviceList = deviceListStore.list

    return (
      <>
        <Nav navbar className="mr-auto">
          {activeDevice && (
            <>
              <NavItem>
                <NavLink to={"/device/dashboard"} className="nav-link">
                  <Icon name="th" fw /> <span className="nav-text">Dashboard</span>
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav className="nav-link-dropdown">
                  <Icon name="cog" fw /> <span className="nav-text">Settings</span>
                </DropdownToggle>
                <DropdownMenu>
                  <LinkContainer to={"/device/settings/notifications"}>
                    <DropdownItem>
                      <Icon name="bell" fw /> <span className="nav-text">Notifications</span>
                    </DropdownItem>
                  </LinkContainer>
                  <LinkContainer to={"/device/settings/energy-rate"}>
                    <DropdownItem>
                      <Icon name="dollar-square" fw /> <span className="nav-text">Energy Rate</span>
                    </DropdownItem>
                  </LinkContainer>
                  <LinkContainer to={"/device/settings/application-mode"}>
                    <DropdownItem>
                      <Icon name="sliders-h" fw /> <span className="nav-text">Application Mode</span>
                    </DropdownItem>
                  </LinkContainer>
                  <LinkContainer to={"/device/settings/reserve-limit"}>
                    <DropdownItem>
                      <Icon name="battery-half" fw /> <span className="nav-text">Reserve Limit</span>
                    </DropdownItem>
                  </LinkContainer>
                  <LinkContainer to={"/device/settings/reboot"}>
                    <DropdownItem>
                      <Icon name="reboot" fw /> <span className="nav-text">Reboot</span>
                    </DropdownItem>
                  </LinkContainer>
                  {username === "roman444" && (
                    <a href="homepower://connectble" className="dropdown-item">
                      <Icon name="signal-alt" fw /> <span className="nav-text">Network Configuration</span>
                    </a>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav className="nav-link-dropdown">
                  <Icon name="chart-bar" fw /> <span className="nav-text">Reports</span>
                </DropdownToggle>
                <DropdownMenu>
                  <LinkContainer to={"/device/report/power-diagram"}>
                    <DropdownItem>
                      <Icon name="diagram" fw /> <span className="nav-text">Power Diagram</span>
                    </DropdownItem>
                  </LinkContainer>
                  <LinkContainer to={"/device/report/energy"}>
                    <DropdownItem>
                      <Icon name="chart-bar" fw /> <span className="nav-text">Energy Report</span>
                    </DropdownItem>
                  </LinkContainer>
                  <LinkContainer to={"/device/report/heatmap"}>
                    <DropdownItem>
                      <Icon name="heatmap" fw /> <span className="nav-text">Energy Heatmap</span>
                    </DropdownItem>
                  </LinkContainer>
                  <LinkContainer to={"/device/report/monthly"}>
                    <DropdownItem>
                      <Icon name="calendar" fw /> <span className="nav-text">Monthly Dashboard</span>
                    </DropdownItem>
                  </LinkContainer>
                  <LinkContainer to={"/device/report/lifetime"}>
                    <DropdownItem>
                      <Icon name="infinity" fw /> <span className="nav-text">Lifetime Report</span>
                    </DropdownItem>
                  </LinkContainer>
                  <DownloadIntervalData />
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          )}
          <NavItem>
            <NavLink to={"/faq"} className="nav-link">
              <Icon name="exclamation-square" fw /> <span className="nav-text">FAQ</span>
            </NavLink>
          </NavItem>
        </Nav>
        <Nav navbar className="menu-user">
          {deviceList.length > 1 && activeDevice && (
            <UncontrolledDropdown nav inNavbar className="device-select">
              <DropdownToggle nav className="nav-link-dropdown">
                <Icon name="battery-device" fw />
                &nbsp;<span className="device-serial">{activeDevice.serialNumber}</span>
                <span className="placeholder">Select Homepower</span>
              </DropdownToggle>
              <DropdownMenu>
                {deviceList.map((device, key) => {
                  return (
                    <DropdownItem
                      className="device-select-item"
                      key={device.id}
                      active={activeDevice.id !== null && activeDevice.id === device.id}
                      onClick={() => this.changeDeviceHandler(device.id)}
                    >
                      {device.serialNumber}
                    </DropdownItem>
                  )
                })}
                <LinkContainer to={"/device/link"} className="link-battery">
                  <DropdownItem>
                    <Icon name="plus" fw /> <span className="nav-text">Link Homepower</span>
                  </DropdownItem>
                </LinkContainer>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
          <UserNotifications />
          <UncontrolledDropdown nav inNavbar className="profile">
            <DropdownToggle nav className="profile">
              <Icon name="user-alt" fw className="profile-open" />
              <Icon name="times" fw className="profile-close" />
              <span className="nav-text">{window.localStorage.getItem("currentUser")}</span>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem header>
                <Icon name="user-alt" />
                {username}
                <Link to={"/logout"} className="logout">
                  <Icon name="sign-in" />
                </Link>
              </DropdownItem>
              <LinkContainer to={"/profile"}>
                <DropdownItem>
                  <Icon name="user-circle" fw /> <span className="nav-text">Profile</span>
                </DropdownItem>
              </LinkContainer>
              {/*<LinkContainer to={ "/settings" }>*/}
              {/*  <DropdownItem>*/}
              {/*    <Icon name="cog" fw /> <span className="nav-text">Settings</span>*/}
              {/*  </DropdownItem>*/}
              {/*</LinkContainer>*/}
              <LinkContainer to={"/device/link"} className="link-battery">
                <DropdownItem>
                  <Icon name="plus" fw /> <span className="nav-text">Link New Homepower</span>
                </DropdownItem>
              </LinkContainer>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </>
    )
  }
}

export default withRouter(Menu)

import React from 'react';

class ListErrors extends React.Component {

  render() {
    const errors = this.props.errors;

    if (errors) {
      return (
        <div className="alert alert-danger">
          {
            Object.keys(errors).map(key => {
              return (
                <div key={key}>
                  {errors[key]}
                </div>
              );
            })
          }
        </div>
      );
    } else {
      return null;
    }
  }

}

export default ListErrors;

import React, { Suspense, lazy } from "react"
import { withRouter, Switch, Route, Redirect } from "react-router-dom"
import { inject } from "mobx-react"
import { IntlProvider } from "react-intl"
import AppLocale from "lngProvider"
import Layout from "./components/App/Layout"
import Loader from "./components/Loader"
import ErrorBoundary from "./components/ErrorBoundary/inde"
import User from "./app/User"
import withActivePlant from "./withActivePlant"
import withPageTitle from "./withPageTitle"

const waitFor = (Tag) => (props) => <Tag {...props} />
const waitModal = (Tag, options) => (props) => (
  <Layout.Modal {...options}>
    <Tag {...props} />
  </Layout.Modal>
)

const Home = lazy(() => import("./app/Home/Home"))
const Start = lazy(() => import("./app/Home/Start"))
const DeviceDashboard = lazy(() => import("./app/Device/Dashboard"))
const DeviceReportMonthly = lazy(() => import("./app/Device/Report/Monthly"))
const DeviceReportLifetime = lazy(() => import("./app/Device/Report/Lifetime"))
const DeviceReportPowerDiagram = lazy(() => import("./app/Device/Report/PowerDiagram"))
const DeviceReportEnergy = lazy(() => import("./app/Device/Report/Energy"))
const DeviceReportHeatmap = lazy(() => import("./app/Device/Report/Heatmap"))
const DeviceLink = lazy(() => import("./app/Device/Link/Add"))
const DeviceRemind = lazy(() => import("./app/Device/Link/Remind"))
const DeviceSettingsNotifications = lazy(() => import("./app/Device/Settings/Notifications"))
const DeviceSettingsEnergyRate = lazy(() => import("./app/Device/Settings/EnergyRate"))
const DeviceSettingsApplicationMode = lazy(() => import("./app/Device/Settings/ApplicationMode"))
const DeviceSettingsReserveLimit = lazy(() => import("./app/Device/Settings/ReserveLimit"))
const DeviceSettingsReboot = lazy(() => import("./app/Device/Settings/Reboot"))
const ProfileView = lazy(() => import("./app/Profile/View"))
const ProfileEdit = lazy(() => import("./app/Profile/Edit"))
const ProfileChangePassword = lazy(() => import("./app/Profile/ChangePassword"))
const ProfileDeleteAccount = lazy(() => import("./app/Profile/DeleteAccount"))
const FaqList = lazy(() => import("./app/Faq/List"))
const DevUiKit = lazy(() => import("./app/Dev/UiKit"))
const Error404 = lazy(() => import("./components/Error404"))

const userPages = ["/signin", "/signup", "/recovery"]

const Routes = ({ location, authStore, history }) => {
  const { currentUser } = authStore
  const currentAppLocale = AppLocale["en"]

  if (/signin\/[a-zA-Z0-9]{40}/.test(location.pathname)) {
    return (
      <ErrorBoundary>
        <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
          <Suspense fallback={Loader}>
            <Layout.User>
              <Switch location={location}>
                <Route exact strict path="/signin/:token([a-zA-Z0-9]{40})" component={waitFor(User.SigninWithToken)} />
              </Switch>
            </Layout.User>
          </Suspense>
        </IntlProvider>
      </ErrorBoundary>
    )
  }

  if (userPages.indexOf(location.pathname) > -1) {
    if (currentUser) {
      return <Redirect to="/" />
    }

    return (
      <ErrorBoundary>
        <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
          <Suspense fallback={Loader}>
            <Layout.User>
              <Switch location={location}>
                <Route path={["/signin", "/signup", "/recovery"]} component={waitFor(User.Signin)} />
              </Switch>
            </Layout.User>
            <Switch location={location}>
              <Route exact path="/signup" component={waitModal(User.Signup, { title: "Sign Up", back: "/signin" })} />
              <Route
                exact
                path="/recovery"
                component={waitModal(User.Recovery, { title: "Forgot your password?", back: "/signin", center: true })}
              />
            </Switch>
          </Suspense>
        </IntlProvider>
      </ErrorBoundary>
    )
  }

  if (!currentUser) {
    return <Redirect to={{ pathname: "/signin", state: { from: location } }} />
  }

  if ("/logout" === location.pathname) {
    authStore.logout().then(() => (window.location.href = "/signin"))
    return null
  }

  if (
    "/start" === location.pathname ||
    "/start/link" === location.pathname ||
    "/start/link/remind-verification-code" === location.pathname
  ) {
    return (
      <ErrorBoundary>
        <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
          <Suspense fallback={Loader}>
            <Layout.Start>
              <Route component={waitFor(Start)} />
              <Switch location={location}>
                <Route
                  exact
                  strict
                  path="/start/link"
                  component={waitModal(DeviceLink, {
                    title: "Link new Homepower device",
                    back: "/",
                    size: "xl",
                    center: true,
                    modalClassname: "device-link-page__modal",
                  })}
                />
                <Route
                  exact
                  strict
                  path="/start/link/remind-verification-code"
                  component={waitModal(DeviceRemind, {
                    title: "Don’t know your verification code?",
                    back: "/device/link",
                    center: true,
                  })}
                />
              </Switch>
            </Layout.Start>
          </Suspense>
        </IntlProvider>
      </ErrorBoundary>
    )
  }

  return (
    <ErrorBoundary>
      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        <div className="app-main">
          <Layout.Main location={location}>
            <div>
              <Suspense fallback={Loader}>
                <Switch location={location}>
                  <Route
                    exact
                    strict
                    path={[
                      "/device/dashboard/:date(\\d{4}-\\d{2}-\\d{2})*",
                      "/device/link",
                      "/device/link/remind-verification-code",
                    ]}
                    component={waitFor(withActivePlant(withPageTitle(DeviceDashboard, "Dashboard :id")))}
                  />
                  <Route
                    exact
                    strict
                    path="/device/report/monthly/:date(\d{4}-\d{2})*"
                    component={waitFor(withActivePlant(withPageTitle(DeviceReportMonthly, "Monthly Dashboard :id")))}
                  />
                  <Route
                    exact
                    strict
                    path="/device/report/lifetime"
                    component={waitFor(withActivePlant(withPageTitle(DeviceReportLifetime, "Lifetime Report :id")))}
                  />
                  <Route
                    exact
                    strict
                    path="/device/report/power-diagram/:date(\d{4}-\d{2}-\d{2})*"
                    component={waitFor(withActivePlant(withPageTitle(DeviceReportPowerDiagram, "Power Diagram :id")))}
                  />
                  <Route
                    exact
                    strict
                    path="/device/report/energy/:date(\d{4}-\d{2})*"
                    component={waitFor(withActivePlant(withPageTitle(DeviceReportEnergy, "Energy Report :id")))}
                  />
                  <Route
                    exact
                    strict
                    path="/device/report/heatmap/:reportType(generation|energy-consumption)*/:dateType(hourly|daily)*/:date(\d{4}-\d{2}|\d{4})*"
                    component={waitFor(withActivePlant(withPageTitle(DeviceReportHeatmap, "Heatmap :id")))}
                  />
                  <Route
                    exact
                    strict
                    path="/device/settings/notifications"
                    component={waitFor(
                      withActivePlant(withPageTitle(DeviceSettingsNotifications, "Settings :id - Notifications"))
                    )}
                  />
                  <Route
                    exact
                    strict
                    path="/device/settings/energy-rate"
                    component={waitFor(
                      withActivePlant(withPageTitle(DeviceSettingsEnergyRate, "Settings :id - Energy Rate"))
                    )}
                  />
                  <Route
                    exact
                    strict
                    path="/device/settings/application-mode"
                    component={waitFor(
                      withActivePlant(withPageTitle(DeviceSettingsApplicationMode, "Settings :id - Apllication Mode"))
                    )}
                  />
                  <Route
                    exact
                    strict
                    path="/device/settings/reserve-limit"
                    component={waitFor(
                      withActivePlant(withPageTitle(DeviceSettingsReserveLimit, "Settings :id - Reserve Limit"))
                    )}
                  />
                  <Route
                    exact
                    strict
                    path="/device/settings/reboot"
                    component={waitFor(withActivePlant(withPageTitle(DeviceSettingsReboot, "Settings :id - Reboot")))}
                  />
                  <Route
                    exact
                    strict
                    path="/profile/edit"
                    component={waitFor(withPageTitle(ProfileEdit, "Profile Edit"))}
                  />
                  <Route
                    exact
                    strict
                    path={["/profile", "/profile/change-password"]}
                    component={waitFor(withPageTitle(ProfileView, "Profile"))}
                  />
                  <Route exact strict path="/faq" component={waitFor(withPageTitle(FaqList, "FAQ"))} />
                  {process.env.APP_ENV === "dev" && (
                    <Route exact strict path="/dev/ui-kit" component={waitFor(withPageTitle(DevUiKit, "UI Kit"))} />
                  )}
                  <Route exact strict path="/" component={waitFor(Home)} />
                  <Route component={waitFor(Error404)} />
                </Switch>
                <Switch location={location}>
                  <Route
                    exact
                    strict
                    path="/device/link"
                    component={waitModal(DeviceLink, {
                      title: "Link new Homepower device",
                      back: "/",
                      size: "xl",
                      center: true,
                      modalClassname: "device-link-page__modal",
                    })}
                  />
                  <Route
                    exact
                    strict
                    path="/device/link/remind-verification-code"
                    component={waitModal(DeviceRemind, {
                      title: "Don’t know your verification code?",
                      back: "/device/link",
                      center: true,
                    })}
                  />
                  <Route
                    exact
                    strict
                    path="/profile/change-password"
                    component={waitModal(ProfileChangePassword, {
                      title: "Change Password",
                      back: "/profile",
                      center: false,
                    })}
                  />
                  <Route
                    exact
                    strict
                    path="/profile/delete-account"
                    component={waitModal(ProfileDeleteAccount, {
                      title: "Delete Account",
                      back: "/profile",
                      center: false,
                    })}
                  />
                </Switch>
              </Suspense>
            </div>
          </Layout.Main>
        </div>
      </IntlProvider>
    </ErrorBoundary>
  )
}

export default inject("authStore")(withRouter(Routes))

import React, { Component } from "react"
import { DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"
import sweetalert from "sweetalert"
import activeDeviceStore from "../../stores/ActiveDeviceStore"
import Icon from "../Icons"
import api from "../../api"
import moment from "moment"
import DateRangePicker from "react-bootstrap-daterangepicker"
import "bootstrap-daterangepicker/daterangepicker.css"
import swal from "sweetalert"

const DATE_FORMAT = "DD-MM-YYYY"
const SERVER_FORMAT = "YYYY-MM-DD"

const today = new Date()
const settings = {
  startDate: today,
  endDate: today,
  ranges: {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "This Week": [moment().startOf("week"), moment().endOf("week")],
    "Last Week": [
      moment()
        .subtract(1, "week")
        .startOf("week"),
      moment()
        .subtract(1, "week")
        .endOf("week"),
    ],
  },
  maxDate: new Date(),
  locale: {
    format: "MM-DD-YYYY",
    firstDay: 1,
  },
}

export default class DownloadIntervalData extends Component {
  state = {
    showDialog: false,
    loading: false,
    selectedRange: {
      from: null,
      to: null,
    },
  }

  sendRequest() {
    const deviceId = activeDeviceStore.deviceId
    const { from, to } = this.state.selectedRange
    const showError = () =>
      swal("Oops", "You cannot request interval data at the moment. Please try again later.", "info")
    this.setState({ loading: true })

    return api.Report.requestIntervalData(deviceId, from.format(SERVER_FORMAT), to.format(SERVER_FORMAT))
      .then(({ data }) => {
        if (data.success) {
          swal("Success", "Your report will be emailed to you", "success")
          this.closeDialog()
        } else {
          showError()
        }
      })
      .catch(() => showError())
      .finally(() => this.setState({ loading: false }))
  }

  openDialog() {
    this.setState({ showDialog: true })
  }

  closeDialog() {
    this.setState({ showDialog: false })
    this.setState({
      selectedRange: {
        from: null,
        to: null,
      },
    })
  }

  render() {
    const {
      showDialog,
      selectedRange: { from, to },
      loading,
    } = this.state

    const applyRange = (event, picker) => {
      const { startDate, endDate } = picker
      const diff = endDate.diff(startDate, "days")
      if (diff > 7) {
        swal("Note", "The maximum range you can specify is 7 days", "info")
      } else {
        this.setState({
          selectedRange: {
            from: startDate,
            to: endDate,
          },
        })
      }
    }

    const download = () => {
      this.sendRequest()
    }

    return (
      <>
        <DropdownItem onClick={() => this.openDialog()}>
          <Icon name="download" fw /> <span className="nav-text">Download Interval Data</span>
        </DropdownItem>

        <Modal className="interval-range-modal" isOpen={showDialog} toggle={() => this.closeDialog()}>
          <ModalHeader toggle={() => this.closeDialog()}>Select Date Range</ModalHeader>
          <ModalBody>
            <DateRangePicker initialSettings={settings} onApply={applyRange}>
              <button className="form-control interval-range-modal__input">
                {from && to ? `${from.format(DATE_FORMAT)} — ${to.format(DATE_FORMAT)}` : "Date Range"}
              </button>
            </DateRangePicker>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" disabled={!from || !to || loading} onClick={() => download()}>
              Download
            </Button>{" "}
            <Button onClick={() => this.closeDialog()}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }
}

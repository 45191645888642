import React from "react"
import Logo from "components/App/Logo"
import Footer from "components/App/Footer"
import CookiesClause from "../../CookiesClause"

const User = (props) => {
  return (
    <div className="app-login-page container d-flex flex-column">
      <div className="app-login-container">
        <header className="app-login-header">
          <Logo.Full />
        </header>
        <div className="app-login-content">{props.children}</div>
      </div>
      <CookiesClause />
      <Footer />
    </div>
  )
}

export default User

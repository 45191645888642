import http from './http';

const Weather = {
  current: (plantId) => 
    http.get('/v1/plant/weather/current', {params: {plant_id: plantId}}),
  hourly: (plantId, date) =>
    http.get('/v1/plant/weather/hourly', {params: {plant_id: plantId, date}}),
};

export default Weather;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import classNames from 'classnames';
import {
  conditionallyUpdateScrollbar,
  getOriginalBodyPadding,
  setScrollbarWidth
} from 'reactstrap/lib/utils';

class Modal extends Component {
  constructor(props) {
    super(props);

    this._originalBodyPadding = null;
  }

  componentDidMount() {
    this._originalBodyPadding = getOriginalBodyPadding();
    conditionallyUpdateScrollbar();

    if (Modal.openCount === 0) {
      document.body.className = classNames(document.body.className, 'modal-open');
    }

    Modal.openCount += 1;
  }

  componentWillUnmount() {
    if (Modal.openCount <= 1) {
      const modalOpenClassName = 'modal-open';
      const modalOpenClassNameRegex = new RegExp(`(^| )${modalOpenClassName}( |$)`);
      document.body.className = document.body.className.replace(modalOpenClassNameRegex, ' ').trim();
    }

    Modal.openCount = Math.max(0, Modal.openCount - 1);

    setScrollbarWidth(this._originalBodyPadding);
  }

  render () {
    const { title, back, center, size, modalClassname } = this.props;
    const modalSize = size ? 'modal-' + size : 'modal-lg';

    return (
      <>
        <div className="modal fade show" style={ {display: 'block'} }>
          <div className={classNames('modal-dialog', modalSize, {'modal-dialog-centered': center})}>
            <div className={classNames("modal-content", modalClassname)}>
              {
                (title || back) &&
                <div className="modal-header">
                  {
                    title &&
                    <h5 className="modal-title" id="layout-modal-title">{ title }</h5>
                  }
                  {
                    back &&
                    <Link to={ back } className="close"><span>&times;</span></Link>
                  }
                </div>
              }
              <div className="modal-body">
                { this.props.children }
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show"/>
      </>
    )
  }
}

Modal.openCount = 0;

export default Modal;

import { observable, action } from "mobx"
import api from "api"
import sleep from "../libs/sleep"

class ProfileStore {
  @observable currentUser = null
  fetchingData = false

  set user(user) {
    this.currentUser = user
  }

  get user() {
    if (null === this.currentUser) {
      return this.fetch().then((result) => (this.currentUser = result))
    }

    return this.currentUser
  }

  fetch = async () => {
    if (this.fetchingData) {
      await sleep(250)
      return this.user
    }

    this.fetchingData = true

    return api.Profile.current()
      .then(({ data }) => data)
      .finally(() => {
        this.fetchingData = false
      })
  }

  update = (user) => {
    this.fetchingData = true

    return api.Profile.update(user.username, user.email, user.phone, user.first_name, user.last_name)
      .then(({ data }) => {
        this.currentUser = user
        return data
      })
      .finally(() => {
        this.fetchingData = false
      })
  }

  reload = async () => {
    this.currentUser = await this.fetch()
  }

  @action changePassword = (newPassword, oldPassword) => {
    return api.Profile.updatePassword(newPassword, oldPassword)
  }

  @action deleteAccount = (notes) => {
    return api.Profile.deleteMyProfile(notes)
  }
}

const profileStore = new ProfileStore()

export default profileStore
